interface Item {
    customer_label?: string | null;
    quantity?: string | number | null;
    pack_label?: string | null;
    label?: string | null;
    pack_size?: string | null;
}

/**
 * Formats an order item / basket item / basket item quantity label.
 */
const formatQuantityLabel = (item?: Item, labelDefault = 'x') => {
    const itemWithQuantity = item || {};

    // Customer_label has priority if available.
    if (itemWithQuantity.customer_label) return itemWithQuantity.customer_label;

    if (!itemWithQuantity.quantity) return '';

    // Sets the label and returns with quantity.
    return `${itemWithQuantity.quantity} ${
        itemWithQuantity.pack_label || itemWithQuantity.label || itemWithQuantity.pack_size || labelDefault
    }`;
};

export default formatQuantityLabel;
