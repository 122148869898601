import axios from '@/lib/axios/axios';

// import { MSW_URL } from '../../config/config';

export default {
    /**
     * Returns the patient details for the currently authenticated patient.
     */
    getAuthenticatedPatient: async () => axios.get('/patients/me'),

    /**
     * Updates a patients details.
     * @param {string} uuid - The uuid of the patient to update.
     * @param {object} patchData - The post / patch data containing action and changed details as attributes.
     */
    updatePatient: async (uuid, patchData) => axios.patch(`/patients/${uuid}`, patchData),

    /**
     * Returns all treatments that have previously been ordered by the patient.
     * @param {string} uuid - Uuid of the patient.
     */
    getPreviousTreatments: async (uuid, page, perPage) => {
        const queries = [];

        if (perPage !== null) queries.push(`perPage=${perPage}`);
        if (page !== null) queries.push(`page=${page}`);

        const queryString = queries.length ? `?${queries.join('&')}` : '';

        return axios.get(`/patients/${uuid}/treatments${queryString}`);
    },

    uploadIdPhoto: async (uuid, assetPath) =>
        axios.put(`/patients/${uuid}/identity`, {
            document: assetPath,
        }),
};
