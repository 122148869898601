export const fontWeights = {
    '100': 'font-100',
    '200': 'font-200',
    '300': 'font-300',
    '400': 'font-400',
    '500': 'font-500',
    '600': 'font-600',
    '700': 'font-700',
    '800': 'font-800',
    '900': 'font-900',
};

export const getFontWeightClass = (fontWeight?: keyof typeof fontWeights) => (fontWeight ? fontWeights[fontWeight] : '');
