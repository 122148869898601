import * as React from 'react';
import get from 'lodash.get';

/**
 * This is a pretty standard pattern however inspired
 * from https://www.robinwieruch.de/react-hooks-fetch-data and
 * reworked to use our service pattern.
 */
const dataFetchReducer = (state, action) => {
    /* eslint-disable indent */
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false,
                isSuccess: false,
                error: undefined,
                data: action.payload.clearDataOnRefresh ? null : state.data,
            };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                isSuccess: true,
                data: action.payload.data,
                status: action.payload.status,
            };
        case 'FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.payload.error,
                data: action.payload.data,
                status: action.payload.status,
            };
        case 'FETCH_RESET':
            return {
                ...state,
                ...action.payload,
            };
        default:
            throw new Error();
    }
    /* eslint-enable indent */
};

/**
 *
 * @param {} service function - this needs to be a function which returns a service, if this is a new variable every time it will get caught in a list. Please wrap in useCallback
 * @param {any[]} listenerDependencies - if the service provided depends on a variable that changes, then add the variable in the listeners here as it will need to renew before calling.
 * @param {object} opts - a list of options. See options in the function to see the defaults.
 * @returns {[{data: any, status: number, isError: boolean, isLoading: boolean, error: any}, (...args)=>any, (...args)=>any, (...args)=>any]} - [state, callApi, reset, updateState]
 */
const useDataApi = (service, listenerDependencies = [], opts = {}) => {
    const didCancel = React.useRef();
    const options = {
        initialData: null, // initial data that is in the reducer
        manual: false, // whether to not automatically call this api call on start
        defaultLoading: false, // set whether this is loading by default true or false, handy for displaying a loading screen on pages that require data.
        clearDataOnRefresh: false, // clear the data in the data property when the api is called
        ...opts,
    };
    const srvce = React.useCallback(service, listenerDependencies);
    const [state, dispatch] = React.useReducer(dataFetchReducer, {
        isLoading: options.defaultLoading,
        isSuccess: false,
        isError: false,
        error: undefined,
        data: options.initialData,
        status: 0,
    });

    const callApi = React.useCallback(
        async (...args) => {
            if (!srvce) return null;
            let result;

            dispatch({ type: 'FETCH_INIT', payload: { clearDataOnRefresh: options.clearDataOnRefresh } });

            try {
                result = await srvce(...args);
                if (!didCancel.current) {
                    dispatch({
                        type: 'FETCH_SUCCESS',
                        payload: {
                            data: result.data,
                            status: result.status,
                        },
                    });
                }
            } catch (error) {
                if (!didCancel.current) {
                    dispatch({
                        type: 'FETCH_FAILURE',
                        payload: {
                            error,
                            data: error && error.response && error.response.data ? error.response.data : null,
                            status: get(error, 'response.status', 400),
                        },
                    });
                }
            }
            return result;
        },
        [srvce]
    );

    /**
     * Reset state back to original
     */
    const reset = (stateOverride) => {
        dispatch({
            type: 'FETCH_RESET',
            payload: {
                isLoading: false,
                isError: false,
                isSuccess: false,
                error: null,
                data: options.initialData,
                status: 0,
                ...stateOverride,
            },
        });
    };

    const updateState = (newState, status = 0) => {
        dispatch({
            type: 'FETCH_SUCCESS',
            payload: {
                data: newState,
                status: status || state.state,
            },
        });
    };

    React.useEffect(
        () => () => {
            didCancel.current = true;
        },
        []
    );

    return [state, callApi, reset, updateState];
};

export default useDataApi;
