import React from 'react';
import clx from 'classnames';

import { getLineHeightClass, lineHeights } from './helpers/getLineHeight';
import { getFontSizeClass, fontSizes } from './helpers/getFontSize';
import { getTypesetClass, typesets } from './helpers/getTypeset';
import { getFontWeightClass, fontWeights } from './helpers/getFontWeight';
import { getFontColorClass, fontColors } from './helpers/getFontColor';

type AllowedHtmlTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'ul' | 'p' | 'pre' | 'a' | 'span' | 'div' | 'dt' | 'dd' | 'label' | 'blockquote' | 'address' | 'li' | 'legend' | 'time' | 'td';

interface Props extends React.HTMLAttributes<HTMLElement> {
    as?: AllowedHtmlTags;
    className?: string;
    typeset?: keyof typeof typesets;
    size?: keyof typeof fontSizes;
    lineHeight?: keyof typeof lineHeights;
    weight?: keyof typeof fontWeights;
    color?: keyof typeof fontColors;
    underline?: boolean;
    italic?: boolean;
    lineThrough?: boolean;
    htmlFor?: string; // For HTML label tag.
    dateTime?: string; // For HTML time tag.
    cite?: string; // For HTML blockquote tag.
}

const Typography = ({
    as: Tag = 'span',
    className,
    typeset = 'paragraph',
    size,
    lineHeight,
    weight,
    color,
    underline,
    italic,
    lineThrough,
    ...props
}: Props) => {
    const typographyClassName = clx(
        className,
        getTypesetClass(typeset),

        getFontWeightClass(weight),
        getFontSizeClass(size),
        getLineHeightClass(lineHeight),
        getFontColorClass(color),

        underline ? 'underline' : '',
        italic ? 'italic' : '',
        lineThrough ? 'line-through' : ''
    );

    return <Tag {...props} className={typographyClassName} />;
};

export default Typography;
