import React from 'react';

import formatQuantityLabel from '../../Treatment/helpers/formatQuantityLabel';

import Typography from '../../_ui/_blocks/Typography/Typography';
import ImageWithFallback from '../../NextJs/ImageWithFallback/ImageWithFallback';

import styles from './AccountTreatmentList.module.css';

export interface AccountTreatmentListProps {
    items: Array<any>;
    limit?: number | undefined;
    className?: string;
}

const AccountTreatmentList = ({ items, limit = undefined, className = '' }: AccountTreatmentListProps) => {
    let listItems = items;
    if (limit) listItems = listItems.slice(0, limit);

    return (
        <ul className={className}>
            {listItems.map((item) => (
                <li className={styles.item} key={item.reference}>
                    <ImageWithFallback
                        src={!Array.isArray(item.public) && item.public ? item.public.images.featuredUrl : null}
                        fallback="/images/pill.svg"
                        className={styles.image}
                        alt={`The packaging of ${item.treatment}`}
                        width={32}
                        height={32}
                    />
                    <div>
                        <Typography as="div" typeset="heading" size="090">
                            {item.treatment}
                        </Typography>
                        <Typography as="div" size="070" color="quiet">
                            {formatQuantityLabel(item)}
                        </Typography>
                    </div>
                </li>
            ))}
            {items.length > limit! ? (
                <li className={styles.item}>
                    <div className={styles.limit}>
                        <Typography typeset="heading" size="080" lineHeight="1000">
                            + {items.length - limit!}
                        </Typography>
                    </div>
                    <Typography typeset="heading" size="080" lineHeight="1000">
                        {items.length - limit! > 1 ? 'items' : 'item'}
                    </Typography>
                </li>
            ) : null}
        </ul>
    );
};

export default AccountTreatmentList;
