'use client';

import React, { useState, useEffect } from 'react';
import Image from 'next/image';

type ImageProps = React.ComponentProps<typeof Image>;

interface Props extends Omit<ImageProps, 'src' | 'width' | 'height'> {
    fallback: string;
    src: ImageProps['src'] | null | undefined;
    width: Exclude<ImageProps['width'], 'undefined'>;
    height: Exclude<ImageProps['height'], 'undefined'>;
};

/**
 * Extends the NextJS image component with a fallback prop that swaps the image source if the original image
 * fails to load.
 * @see https://nextjs.org/docs/pages/api-reference/components/image
 */
const ImageWithFallback = ({ src, fallback, onError, ...rest }: Props) => {
    const [imgSrc, setImgSrc] = useState(src || fallback);

    const handleError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        // If an onError function is provided, call it with the event.
        if (typeof onError === 'function') {
            onError(event);
        }
        setImgSrc(fallback);
    }

    // If the image changes, reset the imgSrc in case it's currently set to the fallback image.
    useEffect(() => {
        setImgSrc(src || fallback);
    }, [src, fallback]);

    return <Image src={imgSrc} onError={handleError} {...rest } />;
};

export default ImageWithFallback;
