import axios from '@/lib/axios/axios';
import get from 'lodash.get';

export const basketProcessingEvent = 'basket-processing';

const dispatchBasketProcessingOn409Response = (axiosRequest) => (
    axiosRequest.catch((error) => {
        if (typeof window !== 'undefined' && get(error, 'response.status') === 409) {
            window.dispatchEvent(new CustomEvent(basketProcessingEvent));
        }

        throw error;
    })
)

export default {
    /**
     * A get request to the baskets endpoint will return a paginated list of all baskets in the
     * system, scoped to user. This request can be filtered by active state and page.
     *
     * @param {bool} filterForActive - Returns the users active basket only.
     * @param {number} page - Used in pagination for all the baskets.
     * @param {number} perPage - Number of saved baskets per page (API default is 20).
     */
    getAllBaskets: async (filterForActive = false, page = null, perPage = null) => {
        const queries = [];

        if (filterForActive) queries.push('active=1');
        if (perPage !== null) queries.push(`perPage=${perPage}`);
        if (page !== null) queries.push(`page=${page}`);

        const queryString = queries.length ? `?${queries.join('&')}` : '';

        return axios.get(`/baskets${queryString}`);
    },

    /**
     * Returns a fresh copy (no cache) of the authed user's active basket
     */
    getActiveBasket: async () => axios.get('/baskets/active'),

    /**
     * Queries the basket cache to check if new copy is available, if no cache or update required,
     * returns user's active basket data or 404.
     *
     * @param {string} uuid - Unique ID of the basket to poll.
     */
    pollBasket: async (uuid, lastSeen) => axios.post(`/baskets/${uuid}/poll`, lastSeen),

    /**
     * Updates the basket items by deleting, adding or updating them using the patchData.
     *
     * @param {string} uuid - Unique ID of the basket to poll.
     * @param {object} patchData - Object containing "action" and "items" attributes.
     */
    updateBasket: async (uuid, patchData) => (
        dispatchBasketProcessingOn409Response(axios.patch(`/baskets/${uuid}`, patchData))
    ),

    /**
     * A create basket request will always insert a new basket and if valid return the latest copy
     * of the basket for caching application side. Do not use this endpoint for adding, deleting or
     * modifying items. These actions should use the provided update basket endpoint with specified
     * commands.
     *
     * @param {object} postData - Object containing "items" and "total" attributes.
     */
    createBasket: async (postData) => axios.post('/baskets', postData),

    /**
     * Clear the active baskets for the user and flush the cache.
     *
     * @param {string} uuid - Unique ID of the basket to save.
     */
    saveBasket: async (uuid) => axios.put(`/baskets/${uuid}/save`),

    /**
     * Loads a specified basket into the cache and returns copy of loaded basket.
     *
     * @param {string} uuid - Unique ID of the basket to load.
     */
    loadBasket: async (uuid) => (
        dispatchBasketProcessingOn409Response(axios.put(`/baskets/${uuid}/load`))
    ),

    /**
     * Delete the specified basket and clear the cache.
     *
     * @param {string} uuid - Unique ID of the basket to delete.
     */
    deleteBasket: async (uuid) => (
        dispatchBasketProcessingOn409Response(axios.delete(`/baskets/${uuid}`))
    ),

    /**
     * Returns a fresh copy (no cache) of the request basket
     * @param {string} uuid - Unique ID if the basket to get.
     */
    getBasket: async (uuid) => axios.get(`/baskets/${uuid}`),

    /**
     * Checks the basket to ensure all items are still relevant (separate call due to us polling
     * the get basket, this will have more of a resource impact)
     */
    checkBasket: async () => axios.get('/basket/check'),

    /**
     * Checks the basket to highlight any items which would prevent checkout (out of stock, unavaialble etc).
     * @param {string} uuid - Unique ID of the basket to check.
     */
    getBasketBlockers: async (uuid) => axios.get(`/baskets/${uuid}/blockers`),

    /**
     * Converts the basket into a valid draft order for payment processing.
     * @param {string} uuid - Unique ID of the basket to convert.
     * @param {object} putData - Details to create draft order from.
     */
    convertBasketToDraftOrder: async (uuid, putData) => (
        dispatchBasketProcessingOn409Response(axios.put(`/baskets/${uuid}/convert`, putData))
    ),
};
