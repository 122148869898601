import React from 'react';
import Image from 'next/image';

import { icons, iconSizes } from './icon.config';

type ImageProps = React.ComponentProps<typeof Image>;

interface Props extends Omit<ImageProps, 'src' | 'color'> {
    icon: keyof typeof icons;
    size?: keyof typeof iconSizes;
}

const Icon = ({ icon, size = 'small', ...rest }: Props) => (
    <Image {...rest} src={icons[icon]} width={iconSizes[size]} height={iconSizes[size]} />
)

export default Icon;
