"use client";

import { useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';

import { localStorageGet , isLocalStorageAvailable } from '@/helpers/localStorage';

/**
 * watches local storage key for any changes.
 * @param {string} key
 */
export const useLocalStorageWatcher = (key) => {
    // Check localStorage is available.
    const localStorageAvailable = isLocalStorageAvailable;

    // Store the stored item in state unless localStorage is not available.
    const [state, setState] = useState(localStorageAvailable ? localStorageGet(key) : null);

    useEffect(() => {
        // If localStorage is not available.
        if (localStorageAvailable === false) {
            return null;
        }

        const handler = () => {
            setState((s) => (!isEqual(s, localStorageGet(key)) ? localStorageGet(key) : s));
        };

        const visibilityChange = (e) => {
            if (document.visibilityState === 'visible') handler(e);
        };

        window.addEventListener('storage', handler);
        document.addEventListener(
            'visibilitychange',
            visibilityChange
        ); /** For when people load into the tab if they've been working with multiple tabs, resync state. */

        return () => {
            window.removeEventListener('storage', handler);
            document.removeEventListener('visibilitychange', visibilityChange);
        };
    }, [localStorageAvailable]);

    useEffect(() => {
        // If localStorage is not available.
        if (localStorageAvailable === false) {
            return;
        }

        const newVal = localStorageGet(key);

        if (newVal) setState(newVal);
    }, [key, localStorageAvailable]);

    return state;
};

export default useLocalStorageWatcher;
