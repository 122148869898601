export const lineHeights = {
    none: 'leading-none',
    '100': 'leading-100',
    '200': 'leading-200',
    '300': 'leading-300',
    '400': 'leading-400',
    '500': 'leading-500',
    '600': 'leading-600',
    '700': 'leading-700',
    '800': 'leading-800',
    '900': 'leading-900',
    '1000': 'leading-1000',
};

export const getLineHeightClass = (lineHeight?: keyof typeof lineHeights) => (lineHeight ? lineHeights[lineHeight] : '');
