import djs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsDuration from 'dayjs/plugin/duration';
import dayjsIsBetween from 'dayjs/plugin/isBetween';
import dayjsIsoWeek from 'dayjs/plugin/isoWeek';

/**
 * Add plugins to dayjs - https://day.js.org/docs/en/installation/installation
 */
djs.extend(dayjsUtc); // https://day.js.org/docs/en/plugin/utc
djs.extend(dayjsDuration); // https://day.js.org/docs/en/plugin/duration
djs.extend(dayjsIsBetween); // https://day.js.org/docs/en/plugin/is-between
djs.extend(dayjsIsoWeek); // https://day.js.org/docs/en/plugin/iso-week

const dayjs = djs;

export default dayjs;
export type { Dayjs } from 'dayjs';
