"use client";

import { useEffect } from 'react';

import { isLocalStorageAvailable, localStorageSet } from '@/helpers/localStorage';


/**
 *
 * @param {*} key - Key to store against in local storage
 * @param {*} state - state that will get stringified and pushed into local stroage.
 */
const useLocalStorageSync = (key, state) => {
    useEffect(() => {
        // If localStorage is not available.
        if (isLocalStorageAvailable === false) {
            return;
        }

        if (key) localStorageSet(key, state);
    }, [key, state]);
};


export default useLocalStorageSync;
