import React from 'react';
import clx from 'classnames';

enum HrStyles {
    solid = 'border-solid',
    dashed = 'border-dashed',
}

interface Props extends React.HTMLAttributes<HTMLHRElement> {
    borderStyle?: keyof typeof HrStyles;
}

/**
 * Component to manage <hr /> tags.
 */
const Hr = ({ borderStyle = 'solid', className, ...rest }: Props) => {
    const classNames = clx(className, 'border-t border-decorators-subtle', HrStyles[borderStyle]);
    return <hr {...rest} className={classNames} />;
};

export default Hr;
